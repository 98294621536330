import React, { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Box from "components/Layout/Box";
import Input from "components/Forms/Input";
import Button from "components/Forms/Button";
import captureException from "helpers/sentryHelper";
import { useRouter } from "next/router";
import { FormProps } from "./types";

const schema = yup.object({
  email: yup
    .string()
    .required("Campo Richiesto.")
    .email("Email non valida.")
    .nullable(),
});

function EmailForm({ onFormSubmit, email }: FormProps) {
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { query, isReady } = useRouter();

  useEffect(() => {
    if (isReady) {
      const { email: emailFromQueryString } = query;
      reset({
        email: email || emailFromQueryString,
      });
    }
  }, [email, isReady]);

  const sendEmail: SubmitHandler<any> = async (event) => {
    try {
      return await onFormSubmit(event);
    } catch (error) {
      switch (error.message) {
        case "User found":
          setError("email", {
            message:
              "Esiste già un utenza con questa email. Effettua il login.",
          });
          break;
        default:
          captureException({
            key: "component",
            value: "emailform",
            error,
            extra: null,
          });
          setError("email", {
            message: "Si è verificato un errore, riprova più tardi",
          });
          break;
      }
      return false;
    }
  };
  return (
    <>
      <p className="text-2xl md:text-xl text-center text-gray-darker font-bold mb-8 mt-4">
        Inserisci la tua email
      </p>
      <Box className="w-full sm:w-1/2 lg:w-1/3 max-w-[400px] mx-auto">
        <form
          className="w-full"
          method="POST"
          onSubmit={handleSubmit(sendEmail)}
        >
          <p className="subtitle2">
            Leggi l&apos;
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://access.networkdigital360.it/informativa-trattamento-dati-clienti"
            >
              informativa sulla privacy
            </a>
          </p>
          <Input
            register={register}
            className="w-full"
            type="email"
            name="email"
            placeholder="Inserisci la tua email"
            label="Email *"
            error={
              typeof errors?.email?.message === "string"
                ? errors?.email?.message
                : ""
            }
          />
          <Button
            className="w-full btn btn-primary mt-4"
            type="submit"
            label="Accedi"
            loading={isSubmitting}
          />
        </form>
      </Box>
    </>
  );
}

export default EmailForm;
